import * as ethUtils from "ethereumjs-util";

import { getCsrfToken, signIn } from "next-auth/react";
import { useCallback, useEffect, useState } from "react";

import CredentialsProvider from "next-auth/providers/credentials";
import Web3 from "web3";

export let web3: Web3 | undefined;

interface State {
  started: boolean;
  connected: boolean;
}

export const useMetaMask = () => {
  const [state, setState] = useState<State>({ started: false, connected: false });

  const checkConnectedWallet = useCallback(() => {
    web3?.eth?.getAccounts((error, accounts) => {
      if (error != null || !accounts.length) {
        return setState((previousState) => ({ ...previousState, started: true }));
      }

      return setState({ started: true, connected: true });
    });
  }, [setState]);

  const signInWithWallet = useCallback(async () => {
    const csrfToken = await getCsrfToken();

    if (!state.started || typeof window === "undefined") {
      console.log("MetaMask not started.");
      return;
    }

    (window as any).ethereum
      ?.request({ method: "eth_requestAccounts" })
      // Returns an array of web3 addresses.
      .then(async (accounts: string[]) => {
        try {
          const signedCsrf = await (window as any).ethereum?.request({
            method: "personal_sign",
            params: [csrfToken, accounts[0]],
          });

          signIn("credentials", {}, { userAddress: accounts[0], signature: signedCsrf });
        } catch (e) {}
      });
  }, [state.started]);

  useEffect(() => {
    web3 = new Web3((window as any).ethereum as any);
    checkConnectedWallet();
  }, [checkConnectedWallet]);

  return {
    started: state.started,
    connected: state.connected,
    signInWithWallet,
  };
};

// Restrict login to this user
const ALLOWED_USER = "0xbB53C752881fd8E6Dbe4c29131fdBd80D337ae54".toLowerCase();

export const MetaMaskProvider = CredentialsProvider({
  name: "MetaMask",

  authorize: async (credentials: any, req) => {
    const { userAddress, signature } = req.query as any;

    const tokenBuffer = ethUtils.toBuffer("0x" + credentials.csrfToken);
    const tokenHash = ethUtils.hashPersonalMessage(tokenBuffer);
    const signatureParams = ethUtils.fromRpcSig(signature);

    const publicKey = ethUtils.ecrecover(
      tokenHash,
      signatureParams.v,
      signatureParams.r,
      signatureParams.s
    );

    const addressBuffer = ethUtils.publicToAddress(publicKey);
    const address = ethUtils.bufferToHex(addressBuffer);

    if (address.toLowerCase() !== userAddress?.toLowerCase()) {
      return null;
    }

    if (address.toLowerCase() !== ALLOWED_USER) {
      return null;
    }

    // NOTE: wallet identity currently has no DB record. They're simply
    // authenticated via the JWT.
    const user = { email: `${address}@ethereum.org`, ...credentials };
    return user;
  },

  type: "credentials",

  credentials: {},
});

import GoogleIconSVG from "@/components/site/icons/Google";
import MetaMaskSVG from "@/components/site/icons/MetaMask";
import classNames from "classnames";

// Create an Icon component that renders an SVG and allows us to tint all SVGs,
// regardless of the design or color complexity.
const createIcon = (Component: any) => {
  const Icon = ({ className, color }: { className?: string; color?: string }) => {
    return (
      <span className={classNames(className, "relative")}>
        {/* If a color is supplied, the first copy acts as the background */}
        {color && (
          <>
            <Component className={classNames(className, "absolute", color)} fill />
          </>
        )}

        {/* Second copy is the full-color SVG, and if a color is chosen, it's blended with the background */}
        <Component
          className={classNames(className, "absolute", !!color && "mix-blend-luminosity")}
        />
      </span>
    );
  };

  return Icon;
};

export const Google = createIcon(GoogleIconSVG);
export const MetaMask = createIcon(MetaMaskSVG);

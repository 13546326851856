import mixpanel from "@/utils/mixpanel";
import { signIn } from "next-auth/react";
import { MetaMask, Google } from "@/components/site/icons";
import { useMetaMask } from "@/utils/metamask";

type IProvider = {
  name: string;
  Icon: React.ElementType;
  onClick: () => void;
};

const Provider = ({ name, Icon, onClick }: IProvider) => {
  return (
    <div key={name}>
      <button
        type="button"
        title={`Sign in with ${name}`}
        onClick={() => {
          mixpanel.track(`button click: Sign in with ${name}`);
          onClick();
        }}
        className="inline-flex justify-center w-full px-4 py-2.5 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
      >
        <span className="sr-only">Sign in with {name}</span>

        <Icon className="w-5 h-5" aria-hidden="true" color="text-gray-500" />
      </button>
    </div>
  );
};

export default function AuthProviders() {
  const { signInWithWallet } = useMetaMask();

  return (
    <>
      <Provider
        name="Google"
        Icon={Google}
        onClick={() => {
          signIn("google");
        }}
      />

      <Provider
        name="MetaMask"
        Icon={MetaMask}
        onClick={() => {
          signInWithWallet();
        }}
      />
    </>
  );
}
